import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Layout from '../../Common/Layout'
import { getUser } from '../../Services/userService'
import { Table } from 'reactstrap'
import { GetDataWithTokenV2 } from '../../../apiHelper/ApiHelperV2'
import { FaEye, FaRegEdit } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { selectedUser } from '../../../store/actions/userActions'
import { MDBDataTable } from 'mdbreact'

const Employee = () => {
  const [data, setData] = useState([])
  const [callApi, setcallApi] = useState(true)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [activeEmp, setActiveEmp] = useState([])
  const [dataTable, setDataTable] = useState({
    columns: [
      {
        label: 'UserName',
        field: 'userName',
        sort: 'asc',
        width: 150,
        fixed: 'left'
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 300
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 300
      },
      {
        label: 'Mobile',
        field: 'mobile',
        sort: 'asc',
        width: 120
      },
      {
        label: 'Designation',
        field: 'designation',
        sort: 'asc',
        width: 137
      },
      {
        label: 'Department',
        field: 'department',
        sort: 'asc',
        width: 120
      },
      {
        label: 'RM',
        field: 'rm',
        sort: 'asc',
        width: 120
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'asc',
        width: 120,
        fixed: 'right'
      }
    ],
    row: []
  })

  const [filteredData, setFilteredData] = useState([])

  const getData = () => {
    GetDataWithTokenV2('employee/getAllEmployees').then(res => {
      if (res?.success) {
        setFilteredData(res?.data.slice(1))
        setData(res?.data)
        
      }
    })
  }

  useEffect(() => {

    const activeEmployees = filteredData.filter(employee => employee.status === "active");
    setActiveEmp(activeEmployees)

    handleSetDataTable(
      activeEmployees.sort((d1, d2) => (d1.id > d2.id ? -1 : 1))
    )
    
  }, [filteredData])

  const handleSetDataTable = table => {
    let tableValue = []
    table.map(data => {
      tableValue.push({
        userName: data?.username,
        name: data?.name,
        email: data?.email,
        mobile: data?.phone,
        designation: data?.designation,
        department: data?.department,
        rm: data?.reportingManager,
        action: (
          <>
            <Link
              to='/employee-details'
              onClick={() => dispatch(selectedUser(data))}
              className=''
              style={{ marginRight: '12px' }}
            >
              <FaEye style={{ color: '#08b2c9' }} />
            </Link>
            <Link
              to='/edit-employee'
              onClick={() => dispatch(selectedUser(data))}
            >
              <FaRegEdit style={{ color: '#08b2c9' }} />
            </Link>
          </>
        )
      })
    })
    setDataTable({
      ...dataTable,
      rows: tableValue
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Layout>
        <div className='page-content-crumb'>
          <div className='breadcrumb-area'>
            <ol className='breadcrumb'>
              <li className='item'>
                <Link to='/dashboard'>
                  <i className='fa fa-home' aria-hidden='true' />
                </Link>
              </li>
              <li className='item'>Employee</li>
            </ol>
          </div>
          <button className='btn btn-danger' onClick={() => navigate(-1)}>
            <span className='d-none d-md-block'>Back</span>
            <span className='d-block d-md-none'>
              <i className='fa fa-sign-out' aria-hidden='true'></i>
            </span>
          </button>
        </div>

  

        <div className='row'>
          {/* New-Emp table start */}
          <div className='col-12'>
            <div className='card display-card'>
              <div className='card-body p-0'>
                <div className='text-center'>
                  {filteredData?.length !== 0 ? (
                 
                 
                    <MDBDataTable
                      hover
                      striped
                      scrollX
                      fixedColumns={{ left: 1, right: 1 }}
                      fixedColumnsLast
                      bordered
                      noBottomColumns
                      entriesOptions={[10, 25, 50, 75, 100]}
                      entries={10}
                      data={dataTable}
                      infoLabel={['Showing', 'to', 'of', 'entries']}
                      pagesAmount={10}
                      paginationLabel={['<', '>']}
                    />
                  ) : (
                    <p>No Record Found</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* New-POS table end */}
        </div>
      </Layout>
    </>
  )
}

export default Employee
