import axios from "axios";
import cookie from "react-cookies";


export const mainUrlV2 = "https://uatapi.expertcover.in/api/v2/";
// export const mainUrlV2 = "https://api.expertcover.in/api/v2/";

// export const mainUrlV2 = "http://localhost:7685/api/v1/";
// export const mainUrlV2 = "http://localhost:3341/api/v2/";


const appUrl = "";

export const APPURLV2 = () => {
  return appUrl;
};

export const PostDataV2 = (url, data) => {
  let headers = {
    "Content-Type": "application/json",
    "X-localization": "en",
  };
  return axios
    .post(mainUrlV2 + url, data, { headers: headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      return errorStatus;
    });
};

export const GetDataV2 = (url, data) => {
  let headers = {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "123",
  };
  return axios
    .get(mainUrlV2 + url, data, { headers: headers })
    .then((resposne) => {
      return resposne.data;
    })
    .catch((error) => {
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      return errorStatus;
    });
};

export const PostDataWithTokenV2 = (url, data) => {
  console.log("data :-",data)
  let tokens = "";
  if (cookie.load("token")) {
    tokens = cookie.load("token");
  }
  let headers = {
    Authorization: "Bearer " + tokens,
    "Content-Type": "application/json",
  };
  return axios
    .post(mainUrlV2 + url, data, { headers: headers })
    .then((response) => {
      console.log("///====edit user response====/////", response)
      return response.data;
    })
    .catch((error) => {
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      return errorStatus;
    });
};

export const PostImageDataWithTokenV2 = (url, data) => {
  let tokens = "";
  if (cookie.load("token")) {
    tokens = cookie.load("token");
  }
  let headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + tokens,
  };
  return axios
    .post(mainUrlV2 + url, data, { headers: headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      return errorStatus;
    });
};

export const GetDataWithTokenV2 = (url) => {
  let tokens = "";
  if (cookie.load("token")) {
    tokens = cookie.load("token");
  }
  let config = {
    headers: {
      Authorization: "Bearer " + tokens,
      "ngrok-skip-browser-warning": "123",
    },
    params: {},
  };
  return axios
    .get(mainUrlV2 + url, config)
    .then((resposne) => {
      return resposne.data;
    })
    .catch((error) => {
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      return errorStatus;
    });
};


