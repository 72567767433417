import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Layout from '../../Common/Layout'
import { getUser } from '../../Services/userService'
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table
} from 'reactstrap'
import {
  GetDataV2,
  GetDataWithToken,
  PostData
} from '../../../apiHelper/ApiHelperV2'
import { FaDownload, FaEye, FaRegCopy, FaRegEdit } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { selectedUser } from '../../../store/actions/userActions'
import { MDBDataTable } from 'mdbreact'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import { GetData } from '../../../apiHelper/ApiHelper'
import { toast } from 'material-react-toastify'

const TotalBooked = () => {
  const [data, setData] = useState([])
  const [callApi, setcallApi] = useState(true)
  const [activeTab, setActiveTab] = useState('1')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const userDetails = useSelector(state => state?.root?.userDetails)
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleDrawer = () => {
    setIsOpen(prevState => !prevState)
  }
  const [drawerData, setDrawerData] = useState()
  const [dataTable, setDataTable] = useState({
    columns: [
      {
        label: 'Case Type',
        field: 'caseType',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Product Type',
        field: 'productType',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Policy Number',
        field: 'policyNumber',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Insurer',
        field: 'insurer',
        sort: 'asc',
        width: 300
      },
      {
        label: 'Vehicle Number',
        field: 'vehicleNumber',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Customer Name',
        field: 'customerName',
        sort: 'asc',
        width: 150
      },

      {
        label: 'Issue Date',
        field: 'issueDate',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Start Date',
        field: 'startDate',
        sort: 'asc',
        width: 150
      },
      {
        label: 'End Date',
        field: 'endDate',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Premium',
        field: 'premium',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'asc',
        width: 150
      }
    ],
    row: []
  })

  const copyTextToClipboard = text => {
    // Create a temporary textarea element
    const textarea = document.createElement('textarea')
    textarea.value = text
    document.body.appendChild(textarea)

    // Select the text within the textarea
    textarea.select()
    textarea.setSelectionRange(0, 99999) // For mobile devices

    // Copy the selected text to the clipboard
    document.execCommand('copy')

    // Remove the temporary textarea
    document.body.removeChild(textarea)

    // Optionally, you can provide user feedback or handle the copied text

    toast(`Copied: ${text}`, { type: 'success' })
    // alert(`Copied: ${text}`);
  }

  const [exportData, setExportData] = useState([])

  const handleSetDataTable = table => {
    console.log(table, '))))))))))))))))')
    let tableValue = []
    table.map(data => {
      tableValue.push({
        caseType: data?.status,
        productType: data?.policyCategory,
        policyNumber: (
          <span
            onClick={() => {
              copyTextToClipboard(data?.policyNumber)
            }}
            style={{
              cursor: 'pointer',
              paddingRight: '4px'
            }}
          >
            {data?.policyNumber}
            <FaRegCopy style={{ paddingLeft: '5px' }} />
          </span>
        ),
        insurer: data?.insurerName,
        vehicleNumber: data?.vehicleNumber,
        customerName: data?.customerDetails,
        issueDate: data?.policyDetails?.policyIssueDate,
        startDate: data?.policyDetails?.policyStartDate,
        endDate: data?.policyDetails?.policyEndDate,
        premium: (
          <span>
            <p>Gross: {data?.premium?.grossPremium}</p>
            <p>Net: {data?.premium?.netPremium}</p>
          </span>
        ),
        action: (
          <span
            onClick={() => {
              downloadFile(data?.pdf, 'Policy_Document')
            }}
            style={{
              cursor: 'pointer',
              paddingRight: '4px'
            }}
          >
            
            <FaDownload style={{ paddingLeft: '5px' }} />
          </span>
        )
      })
    })
    setDataTable({
      ...dataTable,
      rows: tableValue
    })
  }

  useEffect(() => {
    GetData('admin/total-policy').then(response => {
      if (response?.success === true) {
        const responseData = response?.data.reverse()

        setData(responseData)
        setExportData(responseData)
        handleSetDataTable(
          responseData?.sort((d1, d2) => (d1.id > d2.id ? -1 : 1))
        )
      }
    })
  }, [])

  const downloadFile = (fileUrl, fileName) => {
    if (fileUrl) {
      // Create an anchor element
      const link = document.createElement('a')
      link.href = fileUrl

      // Check if the file is an image
      if (/\.(png|jpg|jpeg|gif)$/i.test(fileUrl)) {
        // If it's an image, convert it to a data URI and set it as the href
        fetch(fileUrl)
          .then(response => response.blob())
          .then(blob => {
            const objectURL = URL.createObjectURL(blob)
            link.href = objectURL
            link.setAttribute('download', fileName || 'image') // Set desired file name here
            // Trigger click event on the anchor element
            link.click()
            // Clean up
            URL.revokeObjectURL(objectURL)
          })
          .catch(error => console.error('Error downloading image:', error))
      } else {
        // For non-image files, just set the download attribute
        link.setAttribute('download', fileName || 'file') // Set desired file name here
        document.body.appendChild(link)
        // Trigger click event on the anchor element
        link.click()
        // Clean up
        document.body.removeChild(link)
      }
    } else {
      console.error('File URL is empty')
    }
  }

  return (
    <>
      <Layout>
        <div className='page-content-crumb'>
          <div className='breadcrumb-area'>
            <ol className='breadcrumb'>
              <li className='item'>
                <Link to='/dashboard'>
                  <i className='fa fa-home' aria-hidden='true' />
                </Link>
              </li>
              <li className='item'>Total Booked</li>
            </ol>
          </div>
          <button className='btn btn-danger' onClick={() => navigate(-1)}>
            <span className='d-none d-md-block'>Back</span>
            <span className='d-block d-md-none'>
              <i className='fa fa-sign-out' aria-hidden='true'></i>
            </span>
          </button>
        </div>

        <div className='row mb-2 mt-0'></div>
        {/* Breadcrumb area end */}
        {/* page-content main section start */}

        <div className='row'>
          {/* New-Emp table start */}
          <div className='col-12'>
            <TabContent activeTab={activeTab}>
              <TabPane tabId='1'>
                <div className='card display-card'>
                  {/* <span>
                    <CSVLink
                    className='btn'
                    style={{ backgroundColor: 'orange' }}
                      headers={headers}
                      data={csvData}
                      filename={'All_Booked_Leads.csv'}
                    >
                      Export
                    </CSVLink>
                  </span> */}
                  <div className='card-body p-0'>
                    <div className='text-center'>
                      {data?.length !== 0 ? (
                        <MDBDataTable
                          hover
                          striped
                          scrollX
                          bordered
                          noBottomColumns
                          entriesOptions={[10, 25, 50, 75, 100]}
                          entries={10}
                          data={dataTable}
                          infoLabel={['Showing', 'to', 'of', 'entries']}
                          pagesAmount={10}
                          paginationLabel={['<', '>']}
                        />
                      ) : (
                        <p>No Record Found</p>
                      )}
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
          {/* New-POS table end */}
        </div>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction='right'
          zIndex={1000}
          lockBackgroundScroll={true}
          size={600}
        >
          <Row style={{ backgroundColor: '#08b2c9', margin: 0 }}>
            <Col>
              <h4>Documents</h4>
            </Col>
          </Row>
          <Row>
            <Col className='container mt-2 p-4'>
              {drawerData?.lead_data?.currentIssuedPolicyDocument && (
                <div className='d-flex col-12 mt-4'>
                  <p className='col-4'>Current Issued Policy</p>
                  <button
                    className='btn btn-primary col-3'
                    onClick={() =>
                      downloadFile(
                        drawerData?.lead_data?.currentIssuedPolicyDocument,
                        'Current_Issued_Policy'
                      )
                    }
                  >
                    Download
                  </button>
                </div>
              )}

              {drawerData?.lead_data?.rc_front_document && (
                <div className='d-flex col-12 mt-4'>
                  <p className='col-4'>RC Front</p>
                  <button
                    className='btn btn-primary col-3'
                    onClick={() =>
                      downloadFile(
                        drawerData?.lead_data?.rc_front_document,
                        'RC_Front'
                      )
                    }
                  >
                    Download
                  </button>
                </div>
              )}

              {drawerData?.lead_data?.rc_back_document && (
                <div className='d-flex col-12 mt-4'>
                  <p className='col-4'>RC Back</p>
                  <button
                    className='btn btn-primary col-3'
                    onClick={() =>
                      downloadFile(
                        drawerData?.lead_data?.rc_back_document,
                        'RC_Back'
                      )
                    }
                  >
                    Download
                  </button>
                </div>
              )}

              {drawerData?.lead_data?.previousYearPolicydocument && (
                <div className='d-flex col-12 mt-4'>
                  <p className='col-4'>Previous Year Policy</p>
                  <button
                    className='btn btn-primary col-3'
                    onClick={() =>
                      downloadFile(
                        drawerData?.lead_data?.previousYearPolicydocument,
                        'Previous_Year_Policy'
                      )
                    }
                  >
                    Download
                  </button>
                </div>
              )}

              {drawerData?.lead_data?.form_29_document && (
                <div className='d-flex mt-4 col-12'>
                  <p className='col-4'>Form 29 / Sales Letter</p>
                  <button
                    className='btn btn-primary col-3'
                    onClick={() =>
                      downloadFile(
                        drawerData?.lead_data?.form_29_document,
                        'Form_29_Sales_Letter'
                      )
                    }
                  >
                    Download
                  </button>
                </div>
              )}

              {drawerData?.lead_data?.other && (
                <div className='d-flex col-12 mt-4'>
                  <p className='col-4'>Other Document</p>
                  <button
                    className='btn btn-primary col-3'
                    onClick={() =>
                      downloadFile(drawerData?.lead_data?.other, 'Other_Doc')
                    }
                  >
                    Download
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </Drawer>
      </Layout>
    </>
  )
}

export default TotalBooked
